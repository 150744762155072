<template>
  <section
    class="m-about container d-flex"
    data-test="about-block"
  >
    <div class="m-about__wrap">
      <h2 class="m-about__title headline-2">
        О проекте
      </h2>
      <p class="m-about__subtitle title-5 grray-dark--text">
        ДомИнтернет — маркетплейс домашнего интернета, где собраны все тарифы
        крупнейших федеральных и местных провайдеров. Точный поиск по адресу,
        удобное сравнение планов, быстрое и выгодное подключение. Вы можете
        оформить заявку самостоятельно онлайн или позвонить нашим экспертам
      </p>
    </div>
    <img
      loading="lazy"
      :src="
        imgUrl(
          'https://api.dominter.net/media/uploads/blog/operators.png',
          1017,
          0,
        )
      "
      alt="operators"
      class="m-about__img"
    >
  </section>
</template>

<script setup lang="ts">
const ctx = useNuxtApp()

const imgUrl = (path: string, w?: number, height?: number) => {
  const url = ctx.$thumborURL.setImagePath(path).resize(w, height).buildUrl()
  return url
}
</script>

<style scoped lang="scss">
.m-about {
  padding-top: 64px;
  @media (max-width: getBreakpoint(desktop)) {
    flex-direction: column;
    padding-top: 48px;
  }
  @media (max-width: getBreakpoint(mobile-lg)) {
    padding-top: 40px;
  }

  &__img {
    width: 678px;
    margin-left: 40px;
    @media (max-width: getBreakpoint(desktop)) {
      margin-left: 0;
      margin-top: 24px;
      width: 100%;
    }
  }
  &__title {
    margin-bottom: 12px;
  }
}
</style>
